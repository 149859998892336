<template>
  <div class="mt-16 pt-10">
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-btn
          outlined
          rounded
          @click="$router.push('/project-edit')"
          color="primary"
          class="mb-2"
        >
          Add New
        </v-btn>
        <v-data-table
          color="primary"
          :headers="headers"
          :items="projects"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.tag="{ item }">
            {{ `${item.tag.charAt(0).toUpperCase()}${item.tag.slice(1)}` }}
          </template>
          <template v-slot:item.published="{ item }">
            <v-icon color="success" v-if="item.published"> mdi-check </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  @click="view(item.id)"
                  color="success"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="success"
                  v-on="on"
                  class="mx-2"
                  v-bind="attrs"
                  @click="edit(item.id)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    selected = item;
                    deleteDialog = true;
                  "
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete Project</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="deleteDialog"
      v-if="selected != null"
    >
      <v-card>
        <v-toolbar color="secondary" dark> Remove Project </v-toolbar>
        <v-card-text>
          <div class="display-1 pt-5">
            {{ selected.name }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="deleteDialog = false">Close</v-btn>
          <v-btn text @click="removeProject(selected.id)" color="error">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { projectsCollection, storage } from "../firebase/credentials";
export default {
  data() {
    return {
      projects: [],
      deleteDialog: false,
      selected: null,
      headers: [
        { text: "Project", align: "start", value: "client" },
        { text: "Name", value: "name" },
        { text: "Category", value: "tag" },
        { text: "Date Completed", value: "dateCompleted" },
        { text: "Published", value: "published", align: "end" },
        { text: "", value: "actions", align: "end" },
      ],
    };
  },
  methods: {
    async getProjects() {
      try {
        let result = await projectsCollection;
        result.onSnapshot((a) => {
          a.docChanges().forEach(async (p) => {
            if (p.type === "added") {
              this.projects.push({
                client: p.doc.data().client,
                dateCompleted: p.doc.data().dateCompleted,
                location: p.doc.data().location,
                name: p.doc.data().name,
                tag: p.doc.data().tag,
                published: p.doc.data().published,
                id: p.doc.id,
                images: [p.doc.data().featured, ...p.doc.data().images] ?? null,
              });
            } else if (p.type === "removed") {
              let id = p.doc.id;
              let index = this.projects.map((obj) => obj.id).indexOf(id);
              this.projects.splice(index, 1);
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    view(id) {
      this.$router.push(`/project/${id}`);
    },
    edit(id) {
      console.log(id);
      this.$router.push(`/project-edit/${id}`);
    },
    removeProject(id) {
      let urls =
        this.projects.images != undefined
          ? this.projects.filter((e) => e.id == id)[0].images
          : null;
      if (urls) {
        for (let i = 0; i < urls.length; i++) {
          const desertRef = storage.ref(urls[i]);
          desertRef.delete();
          console.log(i);
          if (i == urls.length - 1) {
            projectsCollection.doc(id).delete();
            this.deleteDialog = false;
          }
        }
      } else {
        projectsCollection.doc(id).delete();
        this.deleteDialog = false;
      }
    },
  },
  async mounted() {
    await this.getProjects();
  },
};
</script>

<style lang="scss" scoped></style>
