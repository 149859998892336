var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-16 pt-10"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/project-edit')}}},[_vm._v(" Add New ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"primary","headers":_vm.headers,"items":_vm.projects,"items-per-page":5},scopedSlots:_vm._u([{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.tag.charAt(0).toUpperCase()) + (item.tag.slice(1))))+" ")]}},{key:"item.published",fn:function(ref){
var item = ref.item;
return [(item.published)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"},on:{"click":function($event){return _vm.view(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.edit(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){_vm.selected = item;
                  _vm.deleteDialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Project")])])]}}])})],1)],1),(_vm.selected != null)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_vm._v(" Remove Project ")]),_c('v-card-text',[_c('div',{staticClass:"display-1 pt-5"},[_vm._v(" "+_vm._s(_vm.selected.name)+" ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.removeProject(_vm.selected.id)}}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }